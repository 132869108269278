import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isHistoryofPresentingIllness: true,
  isMedicalHistory: false,
  isDentalHistory: false,
  isOcularHistory: false,
  isGynaecology: false,
  isMentalHealth: false,
  isSocioeconomics: false,
  isDental: false,
  isOcular: false,
  isVisionSpring: false,
  isPhysicalExaminationFindings: false,
  isSystemReview: false,
  isAssessmentAndPlan: false,
  isSoapNote: false,
  isReferralsAndMedicalReport: false,
  isAntenatalCare: false,
  activeMenu: 'Presenting-Illness',
};

const patientExamination = createSlice({
  name: 'patientExamination',
  initialState,
  reducers: {
    updateExaminationMenu: (state: any, { payload }) => {
      const lastActive: string = Object.keys(state).find(
        (key) => state[key] === true,
      )!;
      if (payload === 'Presenting-Illness') {
        state[lastActive] = false;
        state.isHistoryofPresentingIllness = true;
        state.activeMenu = payload;
      } else if (payload === 'Medical-History') {
        state[lastActive] = false;
        state.isMedicalHistory = true;
        state.activeMenu = payload;
      } else if (payload === 'Gynaecology-Obstetric') {
        state[lastActive] = false;
        state.isGynaecology = true;
        state.activeMenu = payload;
      } else if (payload === 'Mental-Health') {
        state[lastActive] = false;
        state.isMentalHealth = true;
        state.activeMenu = payload;
      } else if (payload === 'Socioeconomics-Lifestyle') {
        state[lastActive] = false;
        state.isSocioeconomics = true;
        state.activeMenu = payload;
      } else if (payload === 'Systems-Review') {
        state[lastActive] = false;
        state.isSystemReview = true;
        state.activeMenu = payload;
      } else if (payload === 'Examination-Findings') {
        state[lastActive] = false;
        state.isPhysicalExaminationFindings = true;
        state.activeMenu = payload;
      } else if (payload === 'Assessment-Plan') {
        state[lastActive] = false;
        state.isAssessmentAndPlan = true;
        state.activeMenu = payload;
      } else if (payload === 'Dental') {
        state[lastActive] = false;
        state.isDental = true;
        state.activeMenu = payload;
      } else if (payload === 'Ocular') {
        state[lastActive] = false;
        state.isOcular = true;
        state.activeMenu = payload;
      } else if (payload === 'Dental-History') {
        state[lastActive] = false;
        state.isDentalHistory = true;
        state.activeMenu = payload;
      } else if (payload === 'Ocular-History') {
        state[lastActive] = false;
        state.isOcularHistory = true;
        state.activeMenu = payload;
      } else if (payload === 'Vision-Spring') {
        state[lastActive] = false;
        state.isVisionSpring = true;
        state.activeMenu = payload;
      } else if (payload === 'Soap-Note') {
        state[lastActive] = false;
        state.isSoapNote = true;
        state.activeMenu = payload;
      } else if (payload === 'Referrals-Medical') {
        state[lastActive] = false;
        state.isReferralsAndMedicalReport = true;
        state.activeMenu = payload;
      } else if (payload === 'Antenatal-Care') {
        state[lastActive] = false;
        state.isAntenatalCare = true;
        state.activeMenu = payload;
      }
    },
    clearPatientExaminationState: () => {
      return initialState;
    },
  },
});

export const { updateExaminationMenu, clearPatientExaminationState } =
  patientExamination.actions;
export default patientExamination.reducer;
